<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title">
						<div><i></i><span>奖品管理</span></div>
						<el-button size="small" type="primary" @click="add">添加</el-button>
					</div>
					<template v-if="list.length > 0">
						<el-table :data="list" class="form-table" border style="width: 100%">
							<el-table-column prop="cover" label="图片" width="250">
								<template slot-scope="scope">
									<el-image class="img" :src="scope.row.cover"></el-image>
								</template>
							</el-table-column>
							<el-table-column prop="name" label="名称" width="200"></el-table-column>
							<el-table-column prop="describe" label="描述"></el-table-column>
							<el-table-column prop="createTime" label="创建时间" width="200">
								<template slot-scope="scope">
									{{scope.row.createTime | dateTimeFormat}}
								</template>
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="200">
								<template slot-scope="scope">
									<a href="javascript: void(0);" class="operate-btn" @click="edit(scope.row.id)"><i class="i-ico i-edit"></i><span>编辑</span></a>
									<a href="javascript: void(0);" class="operate-btn" @click="del(scope.row.id)"><i class="i-ico i-delete"></i><span>删除</span></a>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
					</template>
					<noData v-else text="暂时还没有数据哦！"></noData>
				</div>
				<el-dialog :title="form.id?'编辑奖品':'添加奖品'" :visible.sync="dialogVisible" width="500px">
					<el-form :model="form" ref="form" :rules="rules" label-width="80px">
						<el-form-item label="奖品名称" prop="name">
							<el-input v-model="form.name"></el-input>
						</el-form-item>
						<el-form-item label="奖品数量" prop="prizeNum">
							<el-input v-model="form.prizeNum"></el-input>
						</el-form-item>
						<el-form-item label="奖品名称" prop="cover">
							<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do" accept="image/*" :before-upload="beforeImgUpload" :on-success="uploadSuccess" :show-file-list="false">								
								<img v-if="form.cover" :src="form.cover" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="描述" prop="describe">
							<el-input v-model="form.describe" type="textarea" :rows="3"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="save('form')">保存</el-button>
						<el-button @click="cancel">取消</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData,
	},
	data(){
		return {
			list:[],
			listParams:{
				pageNo:1,
				pageSize:20
			},
			count: 0,
			dialogVisible:false,
			form:{},
			rules:{
				name:[
					{required:true,message:'请输入奖品名称！',trigger:'blur'}
				],
				prizeNum:[
					{required:true,message:'请输入奖品数量！',trigger:'blur'}
				],
				cover:[
					{required:true,message:'请上传图片！',trigger:'blur'}
				],
				describe:[
					{required:true,message:'请输入描述！',trigger:'blur'}
				],
			}
		}
	},
	created(){
		
	},
	computed: {
	},
	watch:{
	},
	mounted(){
		this.getList();
	},
	methods: {
		// 获取模板列表
		getList(){
			const _this = this;
			get('/formadmin/prize/v_list.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					_this.count = res.data.count;
					_this.list = res.data.date;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getList();
		},
		// 编辑
		add(){
			const _this = this;
			_this.dialogVisible = true;
			_this.form = {
				name:'',
				prizeNum:'',
				cover:'',
				describe:'',
			};
			_this.$nextTick(()=>{
				_this.$refs['form'].clearValidate();
			})
		},// 编辑
		edit(id){
			const _this = this;
			get('/formadmin/prize/v_edit.jhtml',{id}).then(res => {
				if(res.code == 200){
					_this.form = res.data.bean;
					_this.dialogVisible = true;
					_this.$nextTick(()=>{
						_this.$refs['form'].clearValidate();
					})
				}
			}).catch(err => {
				_this.$message.error(err);
			})
			
		},
		// 删除
		del(id){
			const _this = this;
			this.$confirm('您是确定删除？删除后不可恢复。', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/prize/prize_delete.jhtml',{id}).then(res => {
					if(res.code == 200){
						_this.getList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},
		// 取消
		cancel(){
			const _this = this;
			_this.dialogVisible = false;
		},
		// 保存
		save(form){
			const _this = this;
			let url = '';
			if(_this.form.id){
				url = '/formadmin/prize/prize_update.jhtml';
			}else{
				url = '/formadmin/prize/prize_save.jhtml';
			}
			_this.$refs[form].validate(valid=>{
				if(valid){
					post(url,_this.form).then(res => {
						if(res.code == 200){
							_this.dialogVisible = false;
							_this.getList();
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}
			})
			
		},
		// 上传前校验文件大小 不能超过5M
		beforeImgUpload(file){
			const isLt5M = file.size / 1024 / 1024 < 5;
			if (!isLt5M) {
				this.$message.error("大小不能超过 5MB!");
			}
			return isLt5M;
		},
		// 上传成功
		uploadSuccess(res){
			console.log(res)
			if(res.code == 200){
				this.form.cover = res.data.modelimg;
			}else{
				this.$message.error(res.message);
			}
		},
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			display: flex;
			justify-content: space-between;
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.i-ico {
			width: 20px;
			height: 20px;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
		}
	}
	.form-table{
		.img{
			width:200px;
			height: 100px;
		}
		.i-ico{
			width:20px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			background-image: url(../../../assets/images/ico_table.png);
		}
		.operate-btn{
			span{
				vertical-align: middle;
			}
			.i-edit{background-position: 3px 3px;}
			.i-delete{background-position: -87px 2px;}
			&:hover{
				color:var(--theme-color);
				.i-edit{background-position: 3px -27px;}
				.i-delete{background-position: -87px -28px;}
			}
		}
		.operate-btn+.operate-btn{margin-left:15px;}
	} 
	::v-deep .el-dialog{
		.el-dialog__header{
			border-bottom:1px solid #DCDFE6;
		}
		.el-dialog__body{
			overflow-y: auto;
			.el-form-item:last-child{
				margin-bottom: 0;
			}
			.avatar-uploader{
				width:100px;
				height: 100px;
				border:1px solid #DCDFE6;
					text-align: center;
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width:100%;
					height: 100%;
					line-height:100px;
				}
				.avatar {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
		}
	} 
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}	
		
}
</style>